import { getStyle, mathCeil, mathMax, mathMin, setStyle, toFloat } from "./dom";

function scrollToTop(el: any) {
    if (!el) return;

    setTimeout(() => {
        el.scrollTop = 0
    })
}

function scrollToBottom(el: any) {
    if (!el) return;
    setTimeout(() => {
        el.scrollTop = el.scrollHeight
    })
}

function scrollToRight(el: any) {
    if (!el) return;
    setTimeout(() => {
        el.scrollLeft = el.scrollWidth
    })
}

function scrollToLeft(el: any) {
    if (!el) return;
    setTimeout(() => el.scrollLeft = 0)
}


function getTextAreaHeight(el: HTMLElement,
    computedMinRows: any,
    computedMaxRows: any = 1,
    noAutoShrink: boolean = false) {

    const computedStyle = getComputedStyle(el);
    // const fontSize = toFloat(computedStyle.fontSize, 0);
    // const lineHeight = toFloat(computedStyle.lineHeight, fontSize);
    const lineHeight = toFloat(computedStyle.lineHeight, 1);

    // Calculate height of border and padding
    const border = toFloat(computedStyle.borderTopWidth, 0) + toFloat(computedStyle.borderBottomWidth, 0)
    const padding = toFloat(computedStyle.paddingTop, 0) + toFloat(computedStyle.paddingBottom, 0)
    // Calculate offset
    const offset = border + padding;
    // Minimum height for min rows (which must be 2 rows or greater for cross-browser support)
    const minHeight = lineHeight * computedMinRows + offset

    // Get the current style height (with `px` units)
    const oldHeight = getStyle(el, 'height') || computedStyle.height
    // Probe scrollHeight by temporarily changing the height to `auto`
    setStyle(el, 'height', 'auto')
    const scrollHeight = el.scrollHeight
    // Place the original old height back on the element, just in case `computedProp`
    // returns the same value as before
    setStyle(el, 'height', oldHeight)

    // Calculate content height in 'rows' (scrollHeight includes padding but not border)
    const contentRows = mathMax((scrollHeight - padding) / lineHeight, 1)
    // Calculate number of rows to display (limited within min/max rows)
    const rows = mathMin(mathMax(contentRows, computedMinRows), computedMaxRows)
    // Calculate the required height of the textarea including border and padding (in pixels)
    const height = mathMax(mathCeil(rows * lineHeight + offset), minHeight)

    // console.log(height)
    // Computed height remains the larger of `oldHeight` and new `height`,
    // when height is in `sticky` mode (prop `no-auto-shrink` is true)
    if (noAutoShrink && toFloat(oldHeight, 0) > height) {
        return oldHeight;
    }

    // Return the new computed CSS height in px units
    return `${height}px`
}

function uniqId() {
    return Math.round(Math.random() * 10000);
}

export {
    scrollToTop,
    scrollToBottom,
    scrollToRight,
    scrollToLeft,
    getTextAreaHeight,
};

